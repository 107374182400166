$(document).ready(function(){

  var toggleNav = function(e) {
    if ( typeof e !== undefined ) e.preventDefault();
    $('#navigation').toggleClass('nav-active');
  }

  $('.flexslider').flexslider({
    controlNav: false
  });

  $('#mobile-nav-toggle').on('click', toggleNav);
  $('#mobile-nav-toggle').on('touchstart', toggleNav);

});
